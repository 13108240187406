import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {DatePipe, HashLocationStrategy, LocationStrategy} from '@angular/common';

// ReactiveForms
import { ReactiveFormsModule } from '@angular/forms';

// NgCharts
import { NgChartsModule } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';

// Storage
import { IonicStorageModule, Storage } from '@ionic/storage-angular';

//jwt
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';

export function tokenGetter() {
    return localStorage.getItem('access_token');
  }

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, IonicModule.forRoot({ mode: 'ios' }), ReactiveFormsModule,
    AppRoutingModule, NgChartsModule, HttpClientModule, IonicStorageModule.forRoot(),JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
          allowedDomains: ["127.0.0.1", "127.0.0.1:8080", "bongapointsforcash.com","https://bongapointsforcash.com", "regent-ai.com"],
          disallowedRoutes: [],
        },
      })],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, {provide:LocationStrategy, useClass:HashLocationStrategy}, DatePipe],
    bootstrap: [AppComponent]
})

export class AppModule {}
